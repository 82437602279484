import React, { useEffect, useState } from "react"
import "./PartnerBox.scss"


function PartnerBox({ }) {

    let partnerList = window.Configs.partnerList || []
    let showCount = 8

    const [Expand, setExpand] = useState(false)

    return (
        <div className="PartnerBox">
            <div className="container-lg text-center">
                <h2 className="mb-5">Unsere Partner:</h2>
                <div className="row justify-content-center">
                    {partnerList.slice(0, Expand ? partnerList.length : showCount).map((i, k) =>
                        <div className="col-6 col-md-4 col-lg-3 align-items-center justify-content-center d-flex p-5" key={k}>
                            <a target="_blank" href={i.url}><img className="w-100" src={i.image} /></a>
                        </div>
                    )}
                </div>
                {partnerList.length > showCount ?
                    <a onClick={() => setExpand(!Expand)}>
                        <button>{Expand ? "Partner einklappen" : "Alle Partner anzeigen"}</button>
                    </a>
                    : null}
            </div>
        </div>
    )
}
export default PartnerBox

