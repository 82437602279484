import React, { useState } from "react";
import AddressBox from "../dashdeisgn/diestadt.app/AddressBox";
import OpeningTimes from "../dashdeisgn/diestadt.app/OpeningTimes";
import "./MapBox.css";

function MapBox() {

  const [GoogleMaps, setGoogleMaps] = useState(false)

  const entryID = 64
  return (
    <div className="MapBox">
      <div className="container-lg">
        <h2>Hier finden Sie uns:</h2>

        <div className="row">
          <div className="col-12 col-lg-9">
            {GoogleMaps ?
              <div class="ratio ratio-4x3 mb-4" >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.876206603264!2d8.959234816043924!3d50.59225418501751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bceddb7489959d%3A0x1980c079a008966d!2sGill%20IT-Service!5e0!3m2!1sde!2sde!4v1640655025476!5m2!1sde!2sde"
                ></iframe>
              </div>
              :
              <div class="alert alert-primary" role="alert">
                <h4 onClick={() => { setGoogleMaps(true) }}>Google Maps aktivieren</h4>
                <p><small>Wir nehmen den Schutz Ihrer Daten sehr ernst. Mit dem Aktivieren der Karte wird ein Google-Maps-iFrame eingebunden. Die Datenschutzerklärung & Nutzungsbedingungen von Google, der Sie mit einer möglichen Aktivierung zustimmen, finden Sie <a target="_blank" href="https://policies.google.com/terms?hl=de">hier</a></small></p>
                <button onClick={() => { setGoogleMaps(true) }}>Karte aktivieren.</button>
              </div>
            }
          </div>

          <div className="col-12 col-lg-3">
            <HeaderBox title="Öffnungszeiten">
              <OpeningTimes id={entryID} />
            </HeaderBox>
            <HeaderBox title="Anschrift">
              <AddressBox id={entryID} />
            </HeaderBox>
          </div>
        </div>
      </div >
    </div >
  );
}
export default MapBox;

function HeaderBox({ title, children }) {
  return (
    <div className="mb-5 mt-5 mt-lg-0">
      <h3>{title}</h3>
      <div className="p-2 pt-3">
        {children}
      </div>
    </div>
  )
}