import React from "react"
import "./ContactOptions.scss"

function ContactOptions({ }) {
    return (
        <div className="mb-5 p-2 d-flex flex-column flex-lg-row">
            {urls.map((i, k) =>
                <div className="mb-2 mx-2">
                    <a target={"_blank"} href={i.url}>
                        <button className="col-12">{i.icon} {i.title}</button>
                    </a>
                </div >
            )}
        </div >
    )
}
export default ContactOptions

let urls = [
    {
        title: "06401 / 223 04 08",
        url: "tel:4964012230408",
        icon: <i class="fas fa-phone"></i>
    },
    {
        title: "info@gill-its.de",
        url: "mailto:info@gill-its.de",
        icon: <i class="fas fa-envelope"></i>,
    },
]