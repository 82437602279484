import React from "react";
import VideoHeader from "../../components/homePage/VideoHeader";
import FeaturesBox from "../../components/homePage/FeaturesBox";
import PriceBox from "../../components/homePage/PriceBox";
import MapBox from "../../components/homePage/MapBox";
import WelcomBox from "../../components/homePage/WelcomBox";
import PartnerBox from "../../components/homePage/PartnerBox";
import "./HomePage.scss"

function HomePage() {


    return (
        <div className="HomePage">
            <VideoHeader />
            <WelcomBox />
            <FeaturesBox />
            <PriceBox />
            <MapBox />
            <PartnerBox />
        </div>
    );
}

export default HomePage;
