import React, { useState } from "react";
import ContactOptions from "../dafaults/ConatctOptions";
import "./WelcomBox.scss";


function WelcomBox() {
  const [Expand, setExpand] = useState(false)
  let terminImg = process.env.PUBLIC_URL + "/assets/images/termin/mittelhessen-termin-online.jpg"
  return (
    <div className="WelcomBox">
      <div className="container-lg p-0 p-lg-5">
        <div className="row">

          <div className="col-12 ">
            <div className="mb-5 p-2">
              <h3 className="red">Über Uns</h3>
              <div className="card">
                <p>Gill IT-Service wurde im Jahr 2005 gegründet und ist ein inhabergeführtes Unternehmen. Wir verstehen uns als Dienstleister für alle Unternehmen die Unterstützung im Betrieb ihrer eigenen EDV-Umgebung suchen. Mit derzeit 4 Mitarbeitern setzen wir auf moderne Managed Service und Cloud Lösungen und starke Partner.</p>

                {Expand ? <>
                  <p>Als Partner im ComTeam Verband können wir auf exzellente Einkaufsbedingungen zu fairen Preisen zugreifen. Daneben gibt es eine breite Palette an technischen-, rechtlichen und vertrieblichen Weiterbildungsmaßnahmen.</p>
                  <p>Seit 2013 sind wir Terra Partner, Terra Cloud Partner und Terra Service Partner. Durch den engen Kontakt direkt zum Hersteller, bei dem der Service großgeschrieben wird, sind Reparaturen in kurzer Zeit erledigt und der Kunde wieder einsatzfähig.</p>
                  <p>Die Wortmann AG, ebenfalls ein inhabergeführtes Unternehmen, bietet alle Lösungen zu den gängigen Cloud Themen zu fairen Konditionen im eigenen deutschen Rechenzentrum an.</p>
                  <p>Unsere Kunden reichen vom Einzelhandel über Solo-Selbständige bis hin zu Ärzten, Zahnärzten, Steuerberatern, Autohäuser, Handwerkern aus allen Gewerken, Soziale Einrichtungen, produzierende Gewerbe bis zu anderen Dienstleistern.</p>
                </> : null}

                <p><a href="#" onClick={() => setExpand(!Expand)}><i class={"fas fa-caret-" + (Expand ? "up" : "down")} ></i> {!Expand ? "Mehr lesen" : "Einklappen"}</a></p>
              </div>

            </div>
          </div>
          {/* <div className="col-12 col-lg-3 ">
          </div> */}
        </div>

        <div className="mb-4 justify-content-center mt-4 d-none d-lg-flex">
          <ContactOptions />
        </div>
      </div>
    </div >
  );
}
export default WelcomBox;
