import React from "react"
import "./AddressBox.scss"

function AddressBox({ id, removeString }) {

    let address = window.Configs.address
    return (
        <div className="AddressBox">
            <span>{address}</span>
        </div>
    )
}
export default AddressBox

