import React from "react";
import CloudServerImg from "../../assets/images/cloudServer.jpg";
import DsvgoImg from "../../assets/images/datenschutz.jpg";
import ScoolImg from "../../assets/images/school.jpg";
import softwareImg from "../../assets/images/software.jpg";
import StrukturierteItImg from "../../assets/images/strukturierteIT.jpg";
import ContactOptions from "../dafaults/ConatctOptions";
import "./FeaturesBox.css";


function FeaturesBox() {
  return (
    <div className="FeaturesBox">
      <div className="container-lg p-0 p-lg-5 d-flex flex-column align-items-center">
        <h2 className="pt-5">Unsere Dienstleistungen:</h2>
        <div className="p-2">
          <div className="row d-flex align-items-stretch">
            {features.map((i, k) => (
              <div className="p-1 p-lg-2 col-lg-4 mb-4 col-md-6 col-12 align-self-stretch">
                <div className="card p-0" key={k}>
                  <h3 className="red">{i.title}</h3>

                  <div className="ratio ratio-16x9">
                    <div
                      className={"imageBox " + i.className}
                      style={{ backgroundImage: `url(${i.image})` }}
                    />
                  </div>

                  <div className="p-4">
                    <p>{i.text}</p>
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>


        <ContactOptions />
      </div >
    </div >
  );
}
export default FeaturesBox;

let features = [
  {
    title: "IT-Betreuung",
    image: softwareImg,
    text: "Durch einen professionellen IT-Support wird sichergestellt, dass die IT-Systeme in Ihrem Unternehmen dauerhaft funktionieren. Mit unseren Service-Dienstleistungen kümmern wir uns rund um die Uhr um Ihre IT-Umgebung.",
  },
  {
    title: "IT-Sicherheit",
    image: DsvgoImg,
    className: "longAnimate",
    text: "Umfassender Schutz vor Sicherheitsbedrohungen: In Zeiten, in denen Hacker-Angriffe auf Unternehmen an der Tagesordnung sind, ist professionelle Unterstützung bei der Absicherung der IT von größter Bedeutung. Die finden Sie hier.",
  },
  {
    title: "IT-Infrastruktur",
    image: StrukturierteItImg,
    text: "Eine optimal strukturierte IT: Das ist die Basis für effiziente computergestützte Prozessabläufe in Ihrem Unternehmen. Unsere IT-Experten passen die IT-Systeme exakt auf Ihre Anforderungen an.",
  },
  {
    title: "Cloud Computing",
    image: CloudServerImg,
    text: "Flexibilität und beliebige Skalierbarkeit: Das verspricht die Nutzung von Cloud-Lösungen für Ihr Unternehmen. Sprechen Sie mit uns über Ihre Möglichkeiten zur standortübergreifenden Zusammenarbeit, zu modernsten Backup-Möglichkeiten und mehr.",
  },
  {
    title: "Schulungen und Trainings",
    image: ScoolImg,
    text: "Angeboten werden Schulungen in allen Bereichen der IT für Anfänger und Einsteiger. Dabei spielen das Alter und der Wissensstand keine Rolle. Mit uns lernen Sie leicht verständlich alles rund um das Thema Betriebssystem, Office, E-Mail, Internet und Sicherheit beim Umgang mit dem PC.",
  },
];
