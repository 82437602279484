import React from "react";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/images/gillLogo.png";
import TeamViewerImg from "../../assets/images/teamViewer/teamViewerLogo.svg";
import "./Header.scss";



function Header() {
  return (
    <div>
      <header className="Header">
        <div className="container-lg">
          <div class="d-flex justify-content-between align-items-center">
            <Link to="/">
              <img src={LogoImg} className="logo" alt="GillIT_logo" />
            </Link>

            <div className="right">
              <a href="https://get.teamviewer.com/gillqs" target="_blank">
                <div className="TeamViewerBox d-none d-lg-flex">
                  <img src={TeamViewerImg} className="TeamViewer" />
                  <span>QuickSupport <i class="fas fa-external-link"></i></span>
                </div>
              </a>
              <div className="links">
                <a href="mailto:info@gill-its.de?body=Sehr%20geehrte%20Damen%20und%20Herren%2C"><i class="fas fa-envelope mr-1"></i> <span class="d-none d-md-block">info@gill-its.de</span></a>
                <a href="tel:4964012230408"><i class="fas fa-phone mr-1"></i> <span class="d-none d-md-block">06401 / 223 04 08</span></a>
                <a target={"_blank"} href="https://www.google.com/maps?ll=50.592251,8.961424&z=17&t=m&hl=de&gl=DE&mapclient=embed&cid=1837680276575655533"><i class="fas fa-map-marker mr-1"></i> <span class="d-none d-md-block">Alsfelder Straße 16</span></a>
              </div>
            </div>
          </div>
        </div >
      </header >
      <div className="headerPush" />
    </div >
  );
}
export default Header;
