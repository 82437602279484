import React from "react"

function ImpressumPage({ }) {
    return (
        <div className="ImpressumPage">
            <div className="container">
                <p className="western" style={{ lineHeight: '100%', marginBottom: '0.21in' }}>
                    <br />
                    <br />
                </p>
                <h1>Impressum</h1>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}>Einzelunternehmer
                    Heiko Gill</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}>Gill
                    IT-Service<br />
                    Alsfelder Straße<br />
                    35305 Grünberg<br />
                    Telefon:
                    064012230408<br />
                    E-Mail: heiko@gill-its.de<br />
                    Internet:
                    <font color="#0000ff"><u><a href="https://www.gill-its.de/">https://www.gill-its.de</a></u></font></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;&nbsp;</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Berufshaftpflichtversicherung:</b><br />
                    Allianz
                    Versicherungs-Aktiengesellschaft<br />
                    Räumlicher Geltungsbereich:
                    Gilt im gesamten EU Gebiet und den Staaten des Abkommens über den
                    europäischen Wirtschaftsraums.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;&nbsp;</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Umsatzsteuer-Identifikationsnummer
                    gemäß § 27a Umsatzsteuergesetz (UStG):</b><br />
                    DE 814337288</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Bildquellenhinweis</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>https://www.pexels.com/</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>https://pixabay.com/</p>

                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Inhaltlich
                    verantwortlich:</b><br />
                    Heiko Gill</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>

            </div>

        </div>
    )
}
export default ImpressumPage