import React from "react"
import "./OpeningTimes.scss"

function OpeningTimes({ id }) {
    let openingTimes = window.Configs.openingTimes

    return (
        <div className="OpeningTimes">
            <span>{openingTimes}</span>
        </div>
    )
}
export default OpeningTimes

function RenderOpeningTimes({ openingData }) {
    console.log(openingData)
    let dayNames = dayNamesNormal
    let weekDaysArray = dayNames

    console.log(openingData.Mo)
    console.log(openingData.Di)
    console.log(openingData.Di == openingData.Mo)

    let shortDays
    let array = [openingData.Mo, openingData.Di, openingData.Mi, openingData.Do, openingData.Fr]

    if (allEqual(array)) {
        dayNames = dayNamesClean
        shortDays = <p><OpeningTimesValue dayData={openingData.Mo} dayName="Mo-Fr" /></p>
    }

    return (<>
        {shortDays}
        {dayNames.map((i, k) =>
            <p>
                <OpeningTimesValue key={k} dayData={openingData[dayNames[k]]} dayName={dayNames[k]} />
            </p>
        )}
    </>)
}
const dayNamesNormal = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]
const dayNamesClean = ["Sa", "So"]
const allEqual = arr => arr.every(v => v.toString() === arr[0].toString())

//Render ein einzelnen Tag
function OpeningTimesValue(props) {

    let { dayData, dayName } = props

    // let { dayName, isOpen, firstValue, secondValue } = props
    let isOpen = Array.isArray(dayData) && dayData.length >= 1

    let value
    if (!isOpen) {
        value = <span>{" "}Geschlossen</span>
    } else {
        value = <div>

            {dayData.map((timeSpan, k) =>
                <div>
                    <span>{timeSpan[0]} - {timeSpan[1]} Uhr</span><br />
                </div>
            )}


            {/* Nach Pause nochmal Offen?
            {secondValue[0] ?
                <span>{secondValue[0]} - {secondValue[1]} Uhr</span>
                : null} */}
        </div>
    }
    return <div className="mb-3">
        <b>{dayName}:</b>
        {value}
    </div>
}
