import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/style/colors.css';
import './assets/style/default.css';
import './assets/style/fonts.css';
import Footer from './components/bars/Footer';
import Header from './components/bars/Header';
import AdPopup from './container/popups/AdPopup';
import MainNavigation from './navigation/MainNavigation';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <AdPopup />
        <MainNavigation />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
