import React from "react";
import "./VideoHeader.css";
import Video from "../../assets/videos/video.mp4";

function VideoHeader() {
  return (
    <div className="VideoHeader">
      <div class="overlay"></div>
      <video
        playsinline="playsinline"
        autoplay="autoplay"
        muted="muted"
        loop="loop"
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div class="container-lg h-100 p-3 pb-1 p-md-5">
        <div class="d-flex h-100 align-items-end">
          <p class="sloagen">
            Gill IT-Service
            <br />
            ... denn EDV ist Vertrauenssache
          </p>
        </div>
      </div>
    </div>
  );
}
export default VideoHeader;
