import React from "react";
import { Link } from "react-router-dom";
import PowerdBy from "../dashdeisgn/PowerdBy";
import AddressBox from "../dashdeisgn/diestadt.app/AddressBox";
import "./Footer.scss";

function Footer() {
  let currentYear = new Date().getFullYear();

  return (
    <footer className="Footer">
      <div className="container-lg pb-4">
        <div className="row">
          {content.map((i, k) => (
            <div className="col-md-4 col-12 text-start mt-5" key={k}>
              <h3>{i.title}:</h3>
              {i.items.length ?
                <div className="mh-4 mb-4">
                  {i.items.map((link, key) => (
                    <div className="text-left" key={key}>
                      {link.url ? (
                        <a href={link.url} target={"_blank"}>
                          {link.title}
                        </a>
                      ) : (
                        <Link to={link.uri}>{link.title}</Link>
                      )}
                    </div>
                  ))}
                </div>
                : null
              }
              <div>{i.customContent}</div>
            </div>
          ))}
        </div>
      </div>

      <hr></hr>

      <div className="copyrightAndRef p-2 pb-5">
        <div className="container-lg">
          <div className="row">

            <div className="col-12 col-lg-6 mb-3">
              <PowerdBy />
            </div>
            <div className="col-12 col-lg-6">
              <p className="m-0">
                © {currentYear || "2022"} - Alle Rechte vorbehalten.
              </p>
            </div>
          </div>
        </div>
      </div >
    </footer >
  );
}
export default Footer;

const content = [

  // {
  //   title: "Partner",
  //   items: [
  //     {
  //       title: "GWG Grünberg",
  //       url: "https://gwg-gruenberg.de/mitglieder/gill-it-service/",
  //     },
  //     {
  //       title: "team@work",
  //       url: "http://www.ihre-teamworker.de/",
  //     },
  //     {
  //       title: "dashdesign;",
  //       url: "https://dashdesign.eu",
  //     },
  //   ],
  // },# 
  {
    title: "Anschrift",
    items: [],
    customContent: (
      <div>
        <p>
          <b>Gill IT-Service</b>
          <AddressBox id={64} />
        </p>
      </div>
    ),
  },
  {
    title: "Kontakt",
    items: [
      {
        title: "info@gill-its.de",
        url: "mailto:info@gill-its.de",
      },
      {
        title: "Tel: 06401 / 223 04 08",
        url: "tel:4964012230408",
      },
    ],
  },


  {
    title: "Rechtliches",
    items: [
      {
        title: "Impressum",
        uri: "/impressum",
      },
      {
        title: "Datenschutz",
        uri: "/datenschutz",
      },
    ],
  },
];
