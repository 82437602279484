import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import DatenschutzPage from '../container/required/DatenschutzPage';
import HomePage from '../container/required/HomePage';
import ImpressumPage from '../container/required/ImpressumPage';
import Page404 from '../container/required/Page404';

function MainNavigation() {

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path="/impressum/" element={<ImpressumPage />} />
                <Route path="/datenschutz/" element={<DatenschutzPage />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </div>
    )
}
export default MainNavigation