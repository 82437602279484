import React from 'react'
import "./DefaultPage.css"

function DefaultPage(props) {
    return (
        <div className={"DefaultPage " + props.className}>
            <div className={props.container ? "container-lg" : null}>
                < div >
                    <h1>{props.title || "props.title"}</h1>
                    <div className={"DefaultPageInner " + props.classNameInner}>
                        {props.children}
                    </div>
                </div>
            </div >
        </div >
    )
}
export default DefaultPage