import React from 'react'
import DefaultPage from '../../components/pages/DefaultPage'

function Page404() {
    return (
        <DefaultPage container title="404" className="Page404">
            <p>Seite nicht gefunden. </p>
        </DefaultPage>
    )
}
export default Page404