import React from "react"

function DatenschutzPage({ }) {
    return (
        <div className="DatenschutzPage">
            <div className="container">
                <p className="western" style={{ lineHeight: '100%', marginBottom: '0.21in' }}>
                    <br />
                    <br />
                </p>

                <h1>Datenschutzerklärung</h1>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p lang="en-US" align="center" style={{ lineHeight: '150%', marginBottom: '0in' }}>
                    <font size={3} style={{ fontSize: '12pt' }}><b><span lang="de-DE">§ 1
                        Allgemeines</span></b></font></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Ihre
                    personenbezogenen Daten (z.&nbsp;B. Anrede, Name, Anschrift,
                    E-Mail-Adresse, Telefonnummer) werden von uns nur gemäß den
                    Bestimmungen des deutschen Datenschutzrechts und des
                    Datenschutzrechts der Europäischen Union (EU) verarbeitet. Die
                    nachfolgenden Vorschriften informieren Sie neben den
                    Verarbeitungszwecken, Rechtsgrundlagen, Empfängern, Speicherfristen
                    auch über Ihre Rechte und den Verantwortlichen für Ihre
                    Datenverarbeitung. Diese Datenschutzerklärung bezieht sich nur auf
                    unsere Websites. Falls Sie über Links auf unseren Seiten auf andere
                    Seiten weitergeleitet werden, informieren Sie sich bitte dort über
                    den jeweiligen Umgang mit Ihren Daten.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p lang="en-US" align="center" style={{ lineHeight: '150%', marginBottom: '0in' }}>
                    <font size={3} style={{ fontSize: '12pt' }}><b><span lang="de-DE">§ 2
                        Kontaktaufnahme</span></b></font></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>(1)
                    Verarbeitungszweck</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Ihre
                    personenbezogenen Daten, die Sie uns per E-Mail, Kontaktformular etc.
                    zur Verfügung stellen, verarbeiten wir zur Beantwortung und
                    Erledigung Ihrer Anfragen. Sie sind nicht verpflichtet, uns Ihre
                    personenbezogenen Daten bereitzustellen. Aber ohne Mitteilung Ihrer
                    E-Mail-Adresse können wir Ihnen auch nicht per E-Mail antworten.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>(2)
                    Rechtsgrundlagen</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>a) Sollten Sie uns
                    eine ausdrückliche Einwilligung zur Verarbeitung Ihrer Daten gegeben
                    haben, ist Art.&nbsp;6 Abs.&nbsp;1a) DSGVO die Rechtsgrundlage für
                    diese Verarbeitung.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>b) Sollten wir Ihre
                    Daten zur Durchführung vorvertraglicher Maßnahmen verarbeiten, ist
                    Art.&nbsp;6 Abs.&nbsp;1b) DSGVO die Rechtsgrundlage.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>c) In allen anderen
                    Fällen (insbesondere bei Nutzung eines Kontaktformulars) ist Art.&nbsp;6
                    Abs.&nbsp;1f) DSGVO die Rechtsgrundlage.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>WIDERSPRUCHSRECHT:
                    Sie haben das Recht, der Datenverarbeitung, die auf der Grundlage des
                    Art.&nbsp;6 Abs.&nbsp;1f) DSGVO erfolgt und nicht der Direktwerbung
                    dient, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
                    jederzeit zu widersprechen.</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Im Falle der
                    Direktwerbung können Sie der Verarbeitung hingegen ohne Angabe von
                    Gründen jederzeit widersprechen. </b>
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>(3) Berechtigtes
                    Interesse</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Unser berechtigtes
                    Interesse an der Verarbeitung besteht darin, mit Ihnen auf schnellem
                    Wege zu kommunizieren und Ihre Anfragen kostengünstig zu
                    beantworten. Wenn Sie uns Ihre Anschrift mitteilen, behalten wir uns
                    vor, diese für postalische Direktwerbung zu verwenden. Ihr Interesse
                    am Datenschutz können Sie durch eine sparsame Datenweitergabe (z.&nbsp;B.
                    Verwendung eines Pseudonyms) wahren.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>(4)
                    Empfängerkategorien</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Hostinganbieter,
                    Versanddienstleister bei Direktwerbung</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>(5) Speicherdauer</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Ihre Daten werden
                    gelöscht, wenn sich aus den Umständen entnehmen lässt, dass Ihre
                    Anfrage bzw. der betroffene Sachverhalt abschließend geklärt ist.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Falls es jedoch zu
                    einem Vertragsschluss kommt, werden die nach Handels- und Steuerrecht
                    erforderlichen Daten von uns für die gesetzlich bestimmten Zeiträume
                    aufbewahrt, also regelmäßig zehn Jahre (vgl. §&nbsp;257 HGB, §&nbsp;147
                    AO).</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>(6)
                    Widerrufsrecht</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sie haben im Fall
                    der Verarbeitung aufgrund Ihrer Einwilligung das Recht, Ihre
                    Einwilligung jederzeit zu widerrufen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p lang="en-US" align="center" style={{ lineHeight: '150%', marginBottom: '0in' }}>
                    <font size={3} style={{ fontSize: '12pt' }}><b><span lang="de-DE">§ 3
                        Weitere Informationen </span></b></font>
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Datenschutzhinweise
                    für Termin Buchungen via Microsoft Bookings&nbsp;</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Wir möchten Sie
                    nachfolgend über die Verarbeitung personenbezogener Daten im
                    Zusammenhang mit der Nutzung von "Bookings" informieren.
                    Der Dienst Microsoft Bookings ist ein Teil von Microsoft Office 365.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Zweck der
                    Verarbeitung</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Mit der Software ist
                    die Buchung eines Telefon- oder auch Vor-Ort - Termins mit einem
                    Mitarbeiter der Gill IT-Service möglich (bspw. für eine Beratung
                    vor und / oder nach Vertragsschluss).</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Verantwortlicher</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Verantwortlicher für
                    Datenverarbeitung, die im unmittelbaren Zusammenhang mit der
                    Durchführung von Online-Buchungen steht, ist Gill IT-Service.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Bürokommunikation:
                    Microsoft (Microsoft 365, Microsoft Teams)</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Wir nutzen Microsoft
                    Office 365 und Microsoft Bookings, zur Durchführung unserer
                    Terminvereinbarungen mit Kunden über unsere Website.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Microsoft 365 und
                    Microsoft Bookings sind ein Service der Microsoft Ireland Operations,
                    Ltd.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Microsoft Bookings
                    ist Teil der Cloud-Anwendung Office 365. Microsoft behält sich vor
                    Kundendaten zu eigenen Geschäftszwecken zu verarbeiten. Dies stellt
                    für die Nutzer von Microsoft Bookings ein Datenschutz-Risiko dar.
                    Beachten Sie bitte, dass wir auf die Datenverarbeitungen von
                    Microsoft keinen Einfluss haben. In dem Umfang, in dem Microsoft
                    Bookings personenbezogene Daten in Verbindung mit den legitimen
                    Geschäftsvorgängen von Microsoft verarbeitet, ist Microsoft
                    unabhängiger Datenverantwortlicher für diese Nutzung und als
                    solcher verantwortlich für die Einhaltung aller geltenden Gesetze
                    und Verpflichtungen eines Datenverantwortlichen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Weitere
                    Informationen zu Zweck und Umfang der Datenerhebung und ihrer
                    Verarbeitung</b><font color="#333333"><font face="Calibri, serif"><font size={4} style={{ fontSize: '13pt' }}>&nbsp;</font></font></font></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Weitergehende
                    Informationen zum Umgang Ihren Daten finden Sie in der
                    Datenschutzerklärung von Microsoft. Die Rechtsgrundlage für die
                    Verarbeitung Ihrer Daten in Bezug auf den Dienst „Microsoft
                    Bookings“ ist Art. 6 Abs. 1 S. 1 Buchstabe f) DSGVO (Berechtigtes
                    Interesse an der Datenverarbeitung). Das berechtigte Interesse ergibt
                    sich aus unserem Anspruch, Ihnen eine nutzerfreundliche Webseite mit
                    einem breiten Funktionsumfang anzubieten und Ihnen jederzeit die
                    Möglichkeit zu geben, bei Bedarf schnell und einfach einen
                    Telefontermin mit unseren Mitarbeitern vereinbaren zu können. Wir
                    weisen darauf hin, dass Sie nicht verpflichtet sind, Microsoft
                    Bookings zur Vereinbarung eines Termins zu nutzen. Wenn Sie den
                    Dienst nicht nutzen möchten, nutzen Sie bitte eine andere der
                    angebotenen Kontaktmöglichkeiten zur Terminvereinbarung.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Informationen über
                    Microsoft Bookings erhalten Sie in den Datenschutzerklärungen von
                    Microsoft unter&nbsp;<a href="https://privacy.microsoft.com/de-de/privacystatement"><font color="#4f81bd">https://privacy.microsoft.com/de-de/privacystatement</font></a>.
                    Dort erhalten Sie auch weitere Informationen zu Ihren dies
                    bezüglichen Rechten. Microsoft verarbeitet Ihre personenbezogenen
                    Daten auch in den USA. EU–Standardverträge mit Microsoft zu
                    Microsoft Office 365 und Teams sind abgeschlossen, um ein
                    angemessenes Datenschutzniveau zu garantieren.
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Folgende
                    personenbezogene Daten sind Gegenstand der Verarbeitung:</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Angaben zum
                    Benutzer: Vorname, Name, E-Mail-Adresse, Metadaten: z. B. Datum,
                    Uhrzeit, Telefonnummer, Ort.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p lang="en-US" align="center" style={{ lineHeight: '150%', marginBottom: '0in' }}>
                    <font size={3} style={{ fontSize: '12pt' }}><b><span lang="de-DE">§ 4
                        Ihre Rechte als Betroffener</span></b></font></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Werden
                    personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener im
                    Sinne der DSGVO und es stehen Ihnen folgende Rechte gegenüber uns
                    als Verantwortlichen zu:</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>1. Recht auf
                    Auskunft</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sie können im
                    Rahmen des Art.&nbsp;15 DSGVO Auskunft über Ihre von uns
                    verarbeiteten personenbezogenen Daten verlangen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>2. Recht auf
                    Berichtigung</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sollten die Sie
                    betreffenden Angaben nicht (mehr) zutreffend sein, können Sie nach
                    Art.&nbsp;16 DSGVO eine Berichtigung verlangen. Sollten Ihre Daten
                    unvollständig sein, können Sie eine Vervollständigung verlangen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>3. Recht auf
                    Löschung</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sie können unter
                    den Bedingungen des Art.&nbsp;17 DSGVO die Löschung Ihrer
                    personenbezogenen Daten verlangen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>4. Recht auf
                    Einschränkung der Verarbeitung</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sie haben im Rahmen
                    der Vorgaben des Art.&nbsp;18 DSGVO das Recht, eine Einschränkung
                    der Verarbeitung der Sie betreffenden Daten zu verlangen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>5. Recht auf
                    Datenübertragbarkeit</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sie haben nach
                    Art.&nbsp;20 DSGVO das Recht, die Sie betreffenden personenbezogenen
                    Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
                    gängigen und maschinenlesbaren Format zu erhalten oder die
                    Übermittlung an einen anderen Verantwortlichen zu verlangen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><br />
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>6. Recht auf
                    Widerruf der datenschutzrechtlichen Einwilligungserklärung</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Sie haben nach
                    Art.&nbsp;7 Abs.&nbsp;3 DSGVO das Recht, Ihre datenschutzrechtliche
                    Einwilligungserklärung jederzeit zu widerrufen. Die Rechtmäßigkeit
                    der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
                    wird dadurch nicht berührt.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>7. Recht auf
                    Beschwerde bei einer Aufsichtsbehörde</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Wenn Sie der Ansicht
                    sind, dass die Verarbeitung der Sie betreffenden personenbezogenen
                    Daten gegen die DSGVO verstößt, steht Ihnen nach Art.&nbsp;77 DSGVO
                    das Recht auf Beschwerde bei einer Aufsichtsbehörde (insbesondere in
                    dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder
                    des Orts des mutmaßlichen Verstoßes) zu.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Bitte beachten
                    Sie auch Ihr Widerspruchsrecht nach Art.&nbsp;21 DSGVO:</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>a) Allgemein:
                    begründeter Widerspruch erforderlich</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Erfolgt die
                    Verarbeitung Sie betreffender personenbezogener Daten
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>- zur Wahrung
                    unseres überwiegenden berechtigten Interesses (Rechtsgrundlage nach
                    Art.&nbsp;6 Abs.&nbsp;1f) DSGVO) oder</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>- im öffentlichen
                    Interesse (Rechtsgrundlage nach Art.&nbsp;6 Abs.&nbsp;1e) DSGVO),
                </p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>haben Sie das Recht,
                    jederzeit aus Gründen, die sich aus Ihrer besonderen Situation
                    ergeben, gegen die Verarbeitung Widerspruch einzulegen; dies gilt
                    auch für ein auf die Bestimmungen der DSGVO gestütztes Profiling.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Im Fall des
                    Widerspruchs verarbeiten wir die Sie betreffenden personenbezogenen
                    Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige
                    Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
                    und Freiheiten überwiegen, oder die Verarbeitung dient der
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}><b>b) Sonderfall
                    Direktwerbung: einfacher Widerspruch genügt</b></p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Werden die Sie
                    betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu
                    betreiben, haben Sie das Recht, jederzeit und ohne Angabe von Gründen
                    Widerspruch gegen diese Verarbeitung einzulegen; dies gilt auch für
                    das Profiling, soweit es mit solcher Direktwerbung in Verbindung
                    steht.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>Widersprechen Sie
                    der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie
                    betreffenden personenbezogenen Daten nicht mehr für diese Zwecke
                    verarbeitet.</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}><b>Verantwortlicher
                    für die Datenverarbeitung:</b><br />
                    Einzelunternehmer Heiko Gill</p>
                <p align="left" style={{ lineHeight: '150%', marginBottom: '0in' }}>Gill
                    IT-Service<br />
                    Alsfelder Straße<br />
                    35305 Grünberg<br />
                    Telefon:
                    064012230408<br />
                    info@gill-its.de</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
                <p style={{ lineHeight: '150%', marginBottom: '0in' }}>&nbsp;</p>
            </div>

        </div>
    )
}
export default DatenschutzPage