import React, { useEffect, useState } from "react"
import "./AdPopup.scss"

function AdPopup({ }) {

    const [ShowPopup, setShowPopup] = useState(true)
    const [Popup, setPopup] = useState({})

    useEffect(() => {
        setPopup(window.Configs.popup)
    }, [])

    let { visible, image, url, title, text, buttonText = "Werbeanzeige öffnen" } = Popup


    if (visible && ShowPopup) {
        return (
            <div onClick={() => setShowPopup(false)} className="AdPopup p-2 p-lg-5">

                <div className="container-lg ">
                    <a href={url} target="_blank">
                        <img className="w-100 mb-3" src={image} />
                    </a>
                    <h3 className="mb-3">{title}</h3>
                    <p>{text}</p>
                    <div className="d-flex justify-content-between">
                        <a href={url} target="_blank">
                            <button onClick={() => setShowPopup(false)}>{buttonText} <i class="fas fa-external-link"></i></button>
                        </a>

                        <button onClick={() => setShowPopup(false)}>Weiter zur Homepage <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        )
    }
    return null
}
export default AdPopup