import React from "react";
import "./PriceBox.scss";

import GeldBg from "../../assets/images/geld.jpg";

function PriceBox() {
  return (
    <div
      className="PriceBox"
      style={{ backgroundImage: "url(" + GeldBg + ")" }}
    >
      <div className="inner">
        <div className="container-lg mt-4">
          <h2 className="mb-5 mt-5">Serviceangebote:</h2>

          <div className=" mt-4 d-flex align-items-stretch row">
            {prices.map((i, k) => (
              <PriceItem {...i} key={k} />
            ))}

          </div>
        </div>
      </div>
    </div>
  );
}
export default PriceBox;

function PriceItem(i) {
  return (
    <div className="PriceItem col-md-4 col-12 p-2">
      <h3 className="d-block" style={{ background: i.color }}>{i.title}</h3>
      <div className="card p-0">
        <div className="p-4 px-3">
          <div className="d-flex flex-column h-100">

            <div className="contentBox">
              <p>
                <b>{i.desc}</b>
              </p>

              <ul>
                {i.features.map((item, key) => (
                  <li className="vorteil" key={key}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div className="buyBox mt-auto ">
              {/* <h4 className="m-0">
                Bereits ab {i.price || "???"}€
                <br />
              </h4> */}
              <small>Preis abhängig vom Arbeitsplatz / Server</small>

              <a href={i.url}>
                <button className="mt-4 w-100 d-block">Angebot einholen</button>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

const prices = [
  {
    title: "Basic",
    desc: "Vorteile:",
    color: "#ba8e00",
    features: [
      <>Automatisierte Installation von Sicherheitsupdates</>,
      <>Monitoring der Hardwareparameter und Alarmierun</>,
      <>Tägliche Schwachstellenanalyse betriebskritischer Parameter</>,
      <>Alarmierung bei Sicherheitsbedrohungen</>,
      <>Anitivirus und EDR inklusive</>,
      <>Inventarisierung eingesetzter Hard- und Software</>,
    ],
    price: 20,
    url: "mailto:info@gill-its.de?subject=Angebotsanfrage%3A%20Basic&body=Sehr%20geehrte%20Damen%20und%20Heeren%2C%0D%0A",
  },
  {
    title: "Full",
    color: "#777",
    desc: "Vorteile:",
    features: [
      <>Automatisierte Installation von Sicherheitsupdates</>,
      <>Monitoring der Hardwareparameter und Alarmierun</>,
      <>Tägliche Schwachstellenanalyse betriebskritischer Parameter</>,
      <>Alarmierung bei Sicherheitsbedrohungen</>,
      <>Anitivirus und EDR inklusive</>,
      <>Inventarisierung eingesetzter Hard- und Software</>,
      <b>Flatrate Hilfe bei IT-Problemen aus der Ferne</b>,
      <b>Problembehandlung auch Fremdanbietersoftware</b>,
      <b>Unbegrenzte Nutzung der telefonischen IT-Beratung bei Anwenderfragen</b>,
    ],
    price: 40,
    url: "mailto:info@gill-its.de?subject=Angebotsanfrage%3A%20Full&body=Sehr%20geehrte%20Damen%20und%20Heeren%2C%0D%0A",
  },
  {
    title: "Premium",
    color: "#6fbaf2",
    desc: "Vorteile:",
    features: [<>Automatisierte Installation von Sicherheitsupdates</>,
    <>Monitoring der Hardwareparameter und Alarmierun</>,
    <>Tägliche Schwachstellenanalyse betriebskritischer Parameter</>,
    <>Alarmierung bei Sicherheitsbedrohungen</>,
    <>Anitivirus und EDR inklusive</>,
    <>Inventarisierung eingesetzter Hard- und Software</>,
    <>Flatrate Hilfe bei IT-Problemen aus der Ferne</>,
    <>Problembehandlung auch Fremdanbietersoftware</>,
    <>Unbegrenzte Nutzung der telefonischen IT-Beratung bei Anwenderfragen</>,
    <b>Unbegrenzter vor Ort Service bei Arbeitsplatz und Server</b>],
    price: 50,
    url: "mailto:info@gill-its.de?subject=Angebotsanfrage%3A%20Premium&body=Sehr%20geehrte%20Damen%20und%20Heeren%2C%0D%0A"
  },
];
